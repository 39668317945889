
body {
  margin: 0;
  height: 100%;
  background: url('./Resources/paper.png')  ;
  background-size: cover;
  /* background:  */
  /* linear-gradient(#093028, #237A57); */
  /* radial-gradient(circle at top left, #f3e3d6, transparent 50%), 
  radial-gradient(circle at top right, #b4c8c7, transparent 50%), 
  radial-gradient(circle at bottom left, #f3e3d6, transparent 50%), 
  radial-gradient(circle at bottom right, #f3e3d6, transparent 50%); */
  /* background-attachment: fixed; */

  /* background: linear-gradient(120deg, #7BC342, #FFD700, #FFA07A, #87CEEB, #F3E3D6);
  background-size: 200% 200%;
  animation: gradientAnimation 10s ease infinite; */
  /* background: linear-gradient(135deg, #7BC342, 50%, #F3E3D6);
  background-size: cover; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@font-face {
  font-family: 'BasteleurBold';
  src: local('BasteleurBold'),
  url("./font/Basteleur-Bold.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-Family: 'AvenirRoman';
  src: local('AvenirRoman'),
  url('./font/avenir_roman_12.otf') format('opentype');
  font-weight: normal;
}


.basteleurFont {
  font-family: 'BasteleurBold', serif
}

.carousel-image {
  width: 100%;  /* or any specific width */
  max-width: 200px; /* Set a maximum width for the images */
  height: auto; /* This maintains the aspect ratio */
  object-fit: cover;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3e3d6; /* Your chosen color */
  padding: 10px; /* Add padding for spacing */
}

.footer-content {
  /* Style for the content on the left */
  padding: 10px;
}

.footer-map {
  border: none; /* Removes the border from the iframe */
  width: 600px; /* Adjust as necessary */
  height: 450px; /* Adjust as necessary */
}



.faq-container {
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.accordion-custom {
  background-image: url('./Resources/paper_faq.png'); /* Replace with the path to your background image */
  background-size: cover;
  background-position: center;
  color: #fff; /* Adjust text color for readability */
  border-radius: 10px;
  margin-bottom: 10px;
  border: none;
}

.accordion-header-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'BasteleurBold';
  color: #fff; /* Adjust text color for readability */
}

.accordion-reponse-custom {
  font-family: 'AvenirRoman';
  color: #fff; /* Adjust text color for readability */
}